/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "app/core/scss/core";
@import '~@jabil/ui-styles/dist/main.css';
@import '~@jabil/ui-styles/dist/primeng.css';
@import "../node_modules/bootstrap-select/dist/css/bootstrap-select.css";
@import "assets/icons/material-icons/material-design-icons.css";

.table {
  width: 100%;
  height: 100%;
}

html,
body {
  height: 100%;
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
}

.required {
  color: red;
}

.requiredalignment {
  text-align: right;
}

.invalid {

  input,
  textarea {
    border-color: red !important;
    box-shadow: none !important;
  }

  ng-select {
    border: 1px solid red;
    border-radius: 4px;
  }
}

.valid {

  input,
  textarea {
    border-color: green !important;
    box-shadow: none !important;
  }

  ng-select {
    border: 1px solid green;
    border-radius: 4px;
  }
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.overflow-visible {
  overflow: visible !important;
}

.text-white i {
  color: #fff !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.max-modal-container {
  max-width: 30vw !important;
}

.preserve-newlines {

  white-space: pre-line;

}

.site-panel {

  overflow: visible !important;

}



.role-panel {

  .mat-dialog-content {

    max-height: 90vh;

  }

  .mat-dialog-container {

    overflow: scroll !important;

    max-height: 90vh;

  }

}



@media screen and (min-width: 992px) {

  .site-configuration-panel .mat-dialog-container {

    width: 300px;

  }



  .site-panel {

    max-width: 40vw !important;

    width: 40vw !important;

  }



  .building-panel,
  .role-panel,
  .screen-permissions-panel {

    max-width: 25vw !important;

    width: 25vw !important;

  }



  .role-panel {

    overflow: scroll !important;

  }



}



@media screen and (min-width: 768px) and (max-width: 991px) {

  .site-configuration-panel .mat-dialog-container {

    width: 400px;

  }



  .site-panel {

    max-width: 70vw;

  }



  .building-panel,
  .role-panel,
  .screen-permissions-panel {

    max-width: 50vw !important;

  }



}



@media screen and (max-width: 767px) {

  .site-configuration-panel .mat-dialog-container {

    width: 90vw;

  }



  .site-panel,

  .building-panel,

  .role-panel,

  .screen-permissions-panel {

    max-width: 90vw;

  }

}

@media (min-width:768px) and (max-width:992px) {
  .max-modal-container {
    max-width: 50vw !important;
  }
}

@media (max-width:768px) {
  .max-modal-container {
    max-width: 90vw !important;
  }
}

.color-label {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

.block-pointer-events {
  pointer-events: none;
}

.preserve-newlines {
  white-space: pre-line;
}

// Employee Search items

.wrap_employees_information {
  .p-dropdown-items-wrapper {
    // max-width: 450px;
    // width: 450px;

    .p-dropdown-item {
      white-space: break-spaces;
    }
  }
}